body {
  background-color: #f5f5f5;
  color: #4e4e4e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
}

a {
  color: #1e1e1e;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #212121;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  color: #212121;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  color: #4e4e4e;
}

.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a,
.button:hover {
  box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 4px;
  overflow: hidden;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center !important;
}

.overlay-mf {
  background-color: #212121;
}

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: .7;
}

.paralax-mf {
  position: relative;
  padding: 8rem 0;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*--/ Sections /--*/

.sect-4 {
  padding: 4rem 0;
}

.sect-pt4 {
  padding-top: 4rem;
}

.sect-mt4 {
  margin-top: 4rem;
}

/*--/ Title s /--*/

.title-s {
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.1rem;
}

/*--/ Title A /--*/

.title-box {
  margin-bottom: 4rem;
}

.title-a {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-a {
  color: #4e4e4e;
}

.line-mf {
  width: 40px;
  height: 5px;
  background-color: #212121;
  margin: 0 auto;
}

/*--/ Title Left /--*/

.title-box-2 {
  margin-bottom: 3rem;
}

.title-left {
  font-size: 2rem;
  position: relative;
}

.title-left:before {
  content: '';
  position: absolute;
  height: 3px;
  background-color: #212121;
  width: 100px;
  bottom: -12px;
}

/*------/ Box /------*/

.box-pl2 {
  padding-left: 2rem;
}

.box-shadow-full {
  padding: 3rem 1.25rem;
  position: relative;
  background-color: #fff;
  margin-bottom: 3rem;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/*------/ Socials /------*/

.socials {
  padding: 1.5rem 0;
}

.socials ul li {
  display: inline-block;
}

.socials .ico-circle {
  height: 40px;
  width: 40px;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 1.4;
  margin: 0 15px 0 0;
  box-shadow: 0 0 0 3px #212121;
  transition: all 500ms ease;
}

.socials .ico-circle:hover {
  background-color: #212121;
  color: #fff;
  box-shadow: 0 0 0 3px #cde1f8;
  transition: all 500ms ease;
}

/*------/ Ul resect /------*/

.ul-resect,
.socials ul,
.list-ico,
.blog-wrapper .post-meta ul,
.box-comments .list-comments,
.widget-sidebar .list-sidebar,
.widget-tags ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-ico {
  line-height: 2;
}

.list-ico span {
  color: #212121;
  margin-right: 10px;
}

/*------/ Ico Circle /------*/

.ico-circle {
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  line-height: 1.55;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 0 10px #212121;
  display: block;
}

/*------/ Owl Carousel /------*/

.owl-theme .owl-dots {
  text-align: center;
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 7px;
  margin: 5px 5px;
  background: #cde1f8;
  border: 0px solid #cde1f8;
  display: block;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #cde1f8;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #1B1B1B;
  width: 25px;
}

/*--/ Scrolltop s /--*/

.scrolltop-mf {
  position: relative;
  display: none;
}

.scrolltop-mf span {
  z-index: 999;
  position: fixed;
  width: 42px;
  height: 42px;
  background-color: #212121;
  opacity: .7;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 50%;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #212121;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #212121;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*======================================
//--//-->   NAVBAR
======================================*/

.navbar-b {
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.navbar-b.navbar-reduce {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
  content: '';
  position: absolute;
  margin-left: 0px;
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 500ms ease;
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: #1B1B1B;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
  width: 35px;
}

.navbar-b.navbar-trans .show>.nav-link:before,
.navbar-b.navbar-trans .active>.nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show>.nav-link:before,
.navbar-b.navbar-reduce .active>.nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
  width: 35px;
}

.navbar-b.navbar-trans .nav-link:before {
  background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
  color: #fff;
}

.navbar-b.navbar-trans .show>.nav-link,
.navbar-b.navbar-trans .active>.nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  color: #fff;
}

.navbar-b.navbar-reduce {
  transition: all .5s ease-in-out;
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-b.navbar-reduce .nav-link {
  color: #212121;
}

.navbar-b.navbar-reduce .nav-link:before {
  background-color: #212121;
}

.navbar-b.navbar-reduce .nav-link:hover {
  color: #212121;
}

.navbar-b.navbar-reduce .show>.nav-link,
.navbar-b.navbar-reduce .active>.nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  color: #212121;
}

.navbar-b.navbar-reduce .navbar-brand {
  color: #212121;
}

.navbar-b.navbar-reduce .navbar-toggler span {
  background-color: #1B1B1B;
}

.navbar-b .navbar-brand {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}

.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
  padding: .7rem 1.7rem;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
  background-color: #212121;
  color: #fff;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #212121;
}

/*--/ Hamburger Navbar /--*/

.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*======================================
//--//-->   INTRO
======================================*/

.intro {
  height: 100vh;
  position: relative;
  color: #fff;
}

.intro .intro-content {
  text-align: center;
  position: absolute;
}

.intro .overlay-itro {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: .9;
}

.intro .intro-title {
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.intro .text-slider-items {
  display: none;
}

.intro-single {
  height: 350px;
}

.intro-single .intro-content {
  margin-top: 30px;
}

.intro-single .intro-title {
  text-transform: uppercase;
  font-size: 3rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  color: #212121;
}

.intro-single .breadcrumb .breadcrumb-item:before {
  color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
  color: #cde1f8;
}

.intro-single .breadcrumb a {
  color: #fff;
}

/*======================================
//--//-->   ABOUT
======================================*/

.about-mf .box-shadow-full {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.about-mf {
  margin-bottom: 2rem;
}

.about-mf .about-img img {
  margin-left: 10px;
}

.about-mf {
  margin-bottom: 4rem;
}

.skill-mf span {
  color: #4e4e4e;
}

.skill-mf .progress {
  background-color: #cde1f8;
  margin: .5rem 0 1.2rem 0;
  border-radius: 0;
  height: .7rem;
}

.skill-mf .progress .progress-bar {
  height: .7rem;
  background-color: #212121;
}

/*======================================
//--//-->   SERVICES
======================================*/

.service-box {
  background-color: #fff;
  padding: 2.5rem 1.3rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
}

.service-box:hover .ico-circle {
  transition: all 500ms ease;
  color: #fff;
  background-color: #212121;
  box-shadow: 0 0 0 10px #cde1f8;
}

.service-box .service-ico {
  margin-bottom: 1rem;
  color: #1e1e1e;
}

.service-box .ico-circle {
  transition: all 500ms ease;
  font-size: 4rem;
}

.service-box .s-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  padding: .4rem 0;
}

.service-box .s-description {
  color: #4e4e4e;
}

/*======================================
//--//-->   COUNTER
======================================*/

.counter-box {
  color: #fff;
  text-align: center;
}

.counter-ico {
  margin-bottom: 1rem;
}

.counter-ico .ico-circle {
  height: 60px;
  width: 60px;
  line-height: 1.8;
  box-shadow: 0 0 0 10px #cde1f8;
}

.counter-num .counter {
  font-size: 2rem;
  margin-bottom: 0;
}

/*======================================
//--//-->   PORTFOLIO
======================================*/

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
}

.work-box:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding: 2rem 3% 1rem 4%;
}

.work-content .w-more {
  color: #4e4e4e;
  font-size: .8rem;
}

.work-content .w-more .w-ctegory {
  color: #212121;
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #212121;
  float: right;
}

.work-content .w-like a {
  color: #212121;
}

.work-content .w-like .num-like {
  font-size: .7rem;
}

.w-title {
  font-size: 1.2rem;
}

/*======================================
//--//-->   TESTIMONIALS
======================================*/

.testimonials .owl-carousel .owl-item img {
  width: auto;
}

.testimonial-box {
  color: #fff;
  text-align: center;
}

.testimonial-box .author-test {
  margin-top: 1rem;
}

.testimonial-box .author-test img {
  margin: 0 auto;
}

.testimonial-box .author {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem 0;
  display: block;
  font-size: 1.4rem;
}

.testimonial-box .comit {
  font-size: 2rem;
  color: #212121;
  background-color: #fff;
  width: 52px;
  height: 52px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  line-height: 1.6;
}

/*======================================
//--//-->   BLOG
======================================*/

.card-blog .card-body {
  position: relative;
}

.card-blog .card-category-box {
  position: absolute;
  text-align: center;
  top: -16px;
  left: 15px;
  right: 15px;
  line-height: 25px;
  overflow: hidden;
}

.card-blog .card-category {
  display: inline-block;
  color: #fff;
  padding: 0 15px 5px;
  overflow: hidden;
  background-color: #212121;
  border-radius: 4px;
}

.card-blog .card-category .category {
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-size: .7rem;
  letter-spacing: .1px;
  margin-bottom: 0;
}

.card-blog .card-title {
  font-size: 1.3rem;
  margin-top: .6rem;
}

.card-blog .card-description {
  color: #4e4e4e;
}

.card-blog .post-author {
  display: inline-block;
}

.card-blog .post-date {
  color: #4e4e4e;
  display: inline-block;
  float: right;
}

/*======================================
//--//-->   BLOG-SINGLE
======================================*/

.post-box,
.form-comments,
.box-comments,
.widget-sidebar {
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 3rem;
}

.blog-wrapper .article-title {
  font-size: 1.5rem;
}

.blog-wrapper .post-meta {
  margin: 1rem 0;
}

.blog-wrapper .post-meta ul {
  border-left: 4px solid #212121;
  margin-top: 1rem;
}

.blog-wrapper .post-meta ul li {
  display: inline-block;
  margin-left: 15px;
}

.blog-wrapper .post-meta ul a {
  color: #212121;
}

.blog-wrapper .post-meta ul span {
  color: #1e1e1e;
}

.blog-wrapper .blockquote {
  border-left: 4px solid #212121;
  padding: 18px;
  font-style: italic;
}

/*------/ Comments /------*/

.box-comments .list-comments li {
  padding-bottom: 40px;
}

.box-comments .list-comments .comment-avatar {
  display: table-cell;
  vertical-align: top;
}

.box-comments .list-comments .comment-avatar img {
  width: 80px;
  height: 80px;
}

.box-comments .list-comments .comment-author {
  font-size: 1.3rem;
}

.box-comments .list-comments .comment-details {
  display: table-cell;
  vertical-align: top;
  padding-left: 25px;
}

.box-comments .list-comments a {
  color: #212121;
}

.box-comments .list-comments span {
  color: #1e1e1e;
  font-style: italic;
}

.box-comments .comment-children {
  margin-left: 40px;
}

/*------/ Sidebar /------*/

.widget-sidebar .sidebar-title {
  font-size: 1.6rem;
  font-weight: 600;
  border-left: 5px solid #212121;
  padding-left: 15px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.widget-sidebar .list-sidebar li {
  position: relative;
  padding: 6px 0 6px 24px;
}

.widget-sidebar .list-sidebar li:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 1px;
  left: 0;
  background-color: #212121;
  top: 20px;
}

.sidebar-search input {
  background-color: #fff;
  border-radius: 0;
  transition: all 0.5s ease-in-out;
}

.sidebar-search .btn-search {
  background-color: #212121;
  border-color: #212121;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.widget-tags ul li {
  display: inline-block;
  background-color: #212121;
  padding: .2rem .6rem;
  margin-bottom: .5rem;
  border-radius: 15px;
}

.widget-tags ul li a {
  color: #fff;
}

/*======================================
//--//-->   CONTACT
======================================*/

.footer-paralax {
  padding: 4rem 0 0 0;
}

.contact-mf {
  margin-top: 4rem;
}

/*======================================
//--//-->   FOOTER
======================================*/

footer {
  text-align: center;
  color: #fff;
  padding-bottom: 4rem;
}

footer .copyright {
  margin-bottom: .3rem;
}

footer .credits {
  margin-bottom: 0;
}

footer .credits a {
  color: #fff;
}

/*======================================
//--//-->   CONtaCT FORM
======================================*/

#sendmessage {
  color: #212121;
  border: 1px solid #212121;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

/*======================================
//--//-->   BUTTON
======================================*/

.button {
  display: inline-block;
  padding: .3rem .6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: .3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background-color: #0062d3;
  color: #fff;
  transition: all 500ms ease;
}

.button-a {
  background-color: #212121;
  color: #fff;
  border-color: #cde1f8;
}

.button-big {
  padding: .9rem 2.3rem;
  font-size: 1.2rem;
}

.button-rouded {
  border-radius: 5rem;
}

.btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}

@media (min-width: 577px) {
  .counter-box {
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 767px) {
  .about-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }

  .card-blog {
    margin-bottom: 3rem;
  }

  .contact-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }
}

@media (min-width: 768px) {
  .box-shadow-full {
    padding: 3rem;
  }

  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
  }

  .navbar-b.navbar-trans .nav-link:before,
  .navbar-b.navbar-reduce .nav-link:before {
    margin-left: 18px;
  }

  .intro .intro-title {
    font-size: 4.5rem;
  }

  .intro .intro-subtitle {
    font-size: 2.5rem;
  }

  .intro-single .intro-title {
    font-size: 3.5rem;
  }

  .testimonial-box .description {
    padding: 0 5rem;
  }

  .post-box,
  .form-comments,
  .box-comments,
  .widget-sidebar {
    padding: 3rem;
  }

  .blog-wrapper .article-title {
    font-size: 1.9rem;
  }

  .box-comments .list-comments .comment-author {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-box .description {
    padding: 0 8rem;
  }
}

@media (min-width: 1200px) {
  .testimonial-box .description {
    padding: 0 13rem;
  }
}

@media (max-width: 1024px) {
  .bg-image {
    background-attachment: scroll;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

#about .div-img-bg {
  padding-bottom: 30px;
  border: 20px solid #b8a07e;
}

#about .div-img-bg .about-img img {
  width: 100%;
  box-shadow: 0px 0px 85px 0px rgba(0, 0, 0, 0.14);
  margin-top: -60px;
  margin-left: 40px;
  height: 400px;
  object-fit: cover;
}

#about .about-descr .p-heading {
  font-family: 'Playfair Display', serif;
  font-size: 28px;
  text-align: left;
}

.btn-fill {
  color: #fff;
  background-color: #212121;
}

.btn {
  min-width: 145px;
  line-height: 50px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  transition: box-shadow 0.25s ease-in-out;
  -moz-transition: box-shadow 0.25s ease-in-out;
  -webkit-transition: box-shadow 0.25s ease-in-out;
  -o-transition: box-shadow 0.25s ease-in-out;
  -ms-transition: box-shadow 0.25s ease-in-out;
}

#about .btn:hover {
  color: white;
}




.site-section h2,
.site-section h3 {
  font-size: 36px;
  margin: 0;
  text-transform: uppercase;
}



.btn-border {
  background-color: transparent;
  color: #212121;
  border: 1px solid #212121;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-header.line-heading {
  position: relative;
  margin-bottom: 55px;
}

.section-header.line-heading h2 {
  padding-bottom: 0;
  border-left: 1px solid #f1f2f3;
  padding-left: 15px;
}

.section-header h2 {
  letter-spacing: 2px;
  font-weight: 400;
  position: relative;
}


.section-header line-heading {
  position: relative;
}







@keyframes slideLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
}

.slide-left-anim {
  position: relative;
  overflow: hidden;
}

.slide-left-anim:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  content: '';
  background: rgba(255, 255, 255, 0.5);
  transform: translate3d(-100%, 0, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 100%;
  opacity: 0;
}

.slide-left-container:hover .slide-left-anim:after {
  animation: slideLeft 0.75s ease-in-out;
}

.morphext .animated {
  display: inline-block;
}

.to-animate-group .to-animate {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.to-animate-group .to-animate.slide-right {
  will-change: transform;
  transition: transform 1s ease-out;
  -webkit-transition: transform 1s ease-out;
  transform: translate3d(-20px, 0, 0);
  -webkit-transform: translate3d(-20px, 0, 0);
}

.to-animate-group .filled {
  opacity: 0;
  transition: opacity 0.001s;
  -webkit-transition: opacity 0.001s;
  transition-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.to-animate-group .filler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
  transform-origin: 0;
  transform: translateX(0%) scaleY(1) scaleX(0);
  -webkit-transform: translateX(0%) scaleY(1) scaleX(0);
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.to-animate-group .filler.theme-5 {
  background: #4ac366;
}

.to-animate-group .filler.theme-3 {
  background: #3264f5;
}

.to-animate-group .filler.light {
  background: #f1f2f3;
}

.to-animate-group .filler.black {
  background: #000000;
}

.to-animate-group .filler.white {
  background: #ffffff;
}

.to-animate-group.animated .to-animate {
  opacity: 1;
}

.to-animate-group.animated .to-animate.slide-right {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.to-animate-group.animated .filler {
  will-change: width, transform;
  -webkit-animation-name: filler;
  animation-name: filler;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-timing-function: cubic-bezier(0.81, 0.15, 0.26, 0.99);
  animation-timing-function: cubic-bezier(0.81, 0.15, 0.26, 0.99);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.to-animate-group.animated .filled {
  opacity: 1;
}

.to-animate-video {
  width: 80%;
  height: 350px;
  position: relative;
}

.to-animate-video .video {
  display: block;
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.to-animate-video .html5-video {
  position: absolute;
  top: 0;
  left: 35%;
  bottom: 0;
  width: auto;
  height: 100%;
  transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@keyframes filler {
  0% {
    -webkit-transform: translateX(0%) scaleY(1) scaleX(0);
    transform: translateX(0%) scaleY(1) scaleX(0);
  }

  50% {
    -webkit-transform: translateX(0%) scaleY(1) scaleX(1);
    transform: translateX(0%) scaleY(1) scaleX(1);
  }

  100% {
    -webkit-transform: translateX(102%) scaleY(1) scaleX(1);
    transform: translateX(102%) scaleY(1) scaleX(1);
  }
}

.svg-animation {
  display: flex;
  flex-wrap: wrap;
}

.svg-object {
  width: calc(25% - 90px);
  overflow: hidden;
  text-align: center;
}

@media (max-width: 767px) {
  .svg-object {
    width: 100%;
  }
}

.svg-object .image-object {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  display: block;
  opacity: 0;
}

@media (max-width: 767px) {
  .svg-object .image-object {
    width: 200px;
  }
}

.svg-object .image-object svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #00d2b5;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

.svg-object h6 {
  margin: 20px 0 10px 0;
}

.svg-object.show .image-object,
.svg-object:first-child .image-object {
  opacity: 1;
}

.line-object {
  width: 100px;
  height: 75px;
  padding: 0 10px;
  opacity: 0;
}

.line-object svg {
  fill: none;
  stroke: #00d2b5;
  stroke-width: 1;
  stroke-miterlimit: 10;
  stroke-dasharray: 3;
}

.line-object.show {
  opacity: 1;
}

@media (max-width: 767px) {
  .line-object.show {
    height: 90px;
    width: 100px;
    padding: 10px 0;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    margin: 0 auto;
  }
}


.sect-ptb4 {
  padding-bottom: 4rem;
}

/** Width between 768px to 991px
*  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

@media (min-width: 768px) and (max-width: 991px) {
  #about .div-img-bg {
    padding: 0;
  }

  #about .div-img-bg .about-img img {
    margin-top: 0%;
    margin-left: calc(0% - 0px);
  }

  #about .about-descr .p-heading {
    font-size: 20px;
  }

  #about .about-descr .separator {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

/** Width between 767px to 0
*  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

@media (max-width: 767px) {
  #about .div-img-bg .about-img img {
    margin-top: 0%;
    margin-left: calc(0% - 0px);
  }

  #about .about-descr .p-heading {
    font-size: 20px;
  }

  #about .about-descr .separator {
    max-width: 100%;
    margin-bottom: 1rem;
  }

}

@media (max-width: 600px) {
  #about .div-img-bg {
    padding: 0;
  }

  #about .div-img-bg .about-img img {
    margin-top: 0%;
    margin-left: calc(0% - 0px);
  }

  #about .about-descr .p-heading {
    font-size: 20px;
  }

  #about .about-descr .separator {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  #about .div-img-bg {
    padding: 0;
  }

  #about .div-img-bg .about-img img {
    margin-top: 0%;
    margin-left: calc(0% - 0px);
  }

  #about .about-descr .p-heading {
    font-size: 20px;
  }

  #about .about-descr .separator {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 320px) {
  #about .div-img-bg {
    padding: 0;
  }

  #about .div-img-bg .about-img img {
    margin-top: 0%;
    margin-left: calc(0% - 0px);
  }

  #about .about-descr .p-heading {
    font-size: 20px;
  }

  #about .about-descr .separator {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}


.typed-cursor-blink {
  opacity: 1;
}

.typed-cursor-blink{
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
  }